import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({lista}: any) {

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Artikel nummer</TableCell>
            <TableCell align="right">Namn</TableCell>
            <TableCell align="right">I Lager</TableCell>
            <TableCell align="right">Minimum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista.map((row:any) => (
            <TableRow key={row.artnr}>
              <TableCell component="th" scope="row">
                {row.artnr}
              </TableCell>
              <TableCell align="right">{row.namn}</TableCell>
              <TableCell align="right">{row.ilager}</TableCell>
              <TableCell align="right">{row.min}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
